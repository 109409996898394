.button-container {
  width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 10px 10px 0px 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 3px 2px;
  background-color: white;
}

.button,
.button-active {
  padding: 2px 25px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.button-active {
  background-color: var(--border-color);
}
