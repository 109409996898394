.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
}

.input {
  border: 1px solid grey;
  padding: 2px 5px;
  height: 23px;
  cursor: auto;
}

.input-label {
  margin-right: 5px;
}
