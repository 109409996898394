.filter-ul-container {
  /* margin-top: 20px;
  margin-right: 15px; */
  margin: 0;
  max-width: 250px;
  padding: 2px 3px;
  background-color: white;
}

.btn-toggle,
.btn-sm,
.filter-view-item,
.filter-view-item-selected {
  text-align: left;
  font-size: 14px;
  width: 100%;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border: 1px solid var(--border-color-light);
  border-radius: 5px;
}

.btn-toggle:hover,
.btn-sm:hover,
.filter-view-item:hover,
.filter-view-item-selected:hover {
  background-color: var(--hover-link-color);
  border: 1px solid;
}

.btn-sm {
  /* composes: btn-toggle; */
  font-size: 14px;
  width: 240px;
  margin-left: 20px;
}

.btn-link1,
.btn-link2 {
  font-size: 14px;
  text-decoration: none;
  color: black;
}

.btn-link1:hover,
.btn-link2:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

.btn-link1 {
  padding: 0px, auto, 0px, 25px;
}

.btn-link2 {
  padding: 0px, auto, 0px, 20px;
}

.sidebar-link1 {
  margin: 0px 0px 0px 27px;
}

.sidebar-link2 {
  margin: 0px 0px 0px 40px;
}

.v_menu {
  /* display: inline-block; */
  display: flex;
  flex-direction: row;
  margin: 0;
}

.v_block {
  display: inline-block;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 3px;
  /* font-size: 10px; */
  color: red;
  width: 15px;
  height: 15px;
  /* text-align: center; */
  vertical-align: middle;
  /* padding: 1px;
  margin: 2px; */
}

.v_text {
  display: none;
}

.filter-name {
  display: inline-block;
  margin: 0px;
  padding-left: 5px;
  vertical-align: middle;
  /* color: var(--primary-color); */
}

/* Перемикання виглядів */

.filter-view-block {
  display: flex;
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  color: var(--primary-color);
  text-align: center;
}

.filter-view-item,
.filter-view-item-selected {
  text-align: center;
  padding: 2px 0px;
  font-weight: 600;
}

.filter-view-item-selected {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.filter-view-item-selected:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: yellow;
}

/* Блочний вигляд */

.filter-block-group {
  position: relative;
  text-align: left;
  font-size: 14px;
  width: 100%;
  /* max-height: 200px; */
  margin: 3px 0px;
  padding: 5px;
  /* color: var(--primary-color); */
  background-color: var(--bg-gray);
  border: 1px solid var(--border-color-light);
  /* border-radius: 5px; */
}

.filter-search-block-group {
  text-align: left;
  font-size: 14px;
  width: 100%;
  margin: 3px 0px;
  padding: 5px;
  /* color: var(--primary-color); */
  background-color: var(--bg-gray);
  border: 1px solid var(--border-color-light);
  /* border-radius: 5px; */
}

.filter-block-group-title {
  font-size: 14px;
  font-weight: 500;
  padding: 3px 3px;
  user-select: none;
  cursor: pointer;
}

.filter-block-group-title:hover {
  background-color: var(--hover-link-color);
}

.filter-block-item {
  padding-left: 3px;
  cursor: pointer;
}

.filter-block-item:hover {
  background-color: var(--hover-link-color);
}

.filter-block-button {
  position: absolute;
  top: 8px;
  right: 10px;
  /* padding: 0px 2px; */
  /* background-color: var(--secondary-color); */
  /* color: var(--primary-color); */
  /* border: 1px solid var(--primary-color); */
  /* border-radius: 100%; */
}

.filter-price-block {
  display: flex;
  /* flex-direction: row; */
}

.filter-price-group {
  display: flex;
  flex-direction: row;
  margin: 0px 3px;
  align-items: center;
}

.filter-price-input {
  width: 80px;
  border: 1px solid;
  margin: 3px 0px 3px 5px;
}
