.list-container {
  width: 100%;
}

.favorite-list-header-block {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--bg-sec);
  margin: 15px 0px;
}

.favorite-list-header-title-block {
  display: flex;
  align-items: baseline;
}

.favorite-list-header-title,
.favorite-list-header-title-main {
  font-size: 18px;
  font-weight: 500;
}

.favorite-list-header-title-link {
  color: black;
}

.favorite-list-header-title-link:hover {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.favorite-list-header-title-main {
  color: grey;
  margin-left: 5px;
}

.favorite-list-header-icon-block {
  display: flex;
}

.favorite-list-header-icon-item {
  color: var(--primary-color);
  margin-left: 10px;
  cursor: pointer;
}

.favorite-compare-item,
.favorite-compare-item-line {
  padding: 5px 5px 0px 5px;
  cursor: pointer;
}

.favorite-compare-item:hover,
.favorite-compare-item-line:hover {
  background-color: var(--bg-sec);
  border-radius: 5px;
}

.favorite-compare-item-favorite {
  fill: red;
}

.favorite-compare-item-compare {
  fill: green;
}

.favorite-compare-item-star {
  fill: orange;
}

.favorite-compare-item-not-favorite,
.favorite-compare-item-not-compare,
.favorite-compare-item-not-star {
  fill: var(--primary-color);
}

.item-remove {
  fill: rgb(255, 255, 255);
}

.favorite-compare-item-line {
  margin: 5px 10px 0px 5px;
}

.favorite-list-menu-block {
  display: flex;
  align-items: center;
}

.favorite-list-add {
  font-size: 14px;
  margin-left: 10px;
}

.favorite-list-add:hover {
  text-decoration: underline;
  cursor: pointer;
}

.favorite-list-menu-btn {
  position: relative;
  font-size: 16px;
  font-weight: 900;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 0px 5px 5px 5px;
  margin-left: 10px;
  cursor: pointer;
}

.favorite-list-menu-btn-list {
  position: absolute;
  z-index: 2;
  top: 24px;
  right: -1px;
  width: 200px;
  border: 1px solid var(--bg-sec);
  background-color: var(--bg-sec);
  padding: 5px 10px 0px 10px;
}

.favorite-list-menu-btn-item {
  font-size: 14px;
  color: black;
  font-weight: 400;
  margin-bottom: 5px;
  cursor: pointer;
}

.favorite-list-menu-btn-item:hover {
  text-decoration: underline;
}

.edit-list-name-block {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.edit-list-name-input {
  width: 150px;
  border: 1px solid grey;
  margin: 0px 10px;
}

.header-product-list-compare {
  margin-left: 15px;
  font-size: 14px;
  text-decoration: underline;
  color: var(--primary-color);
}

.header-product-list-compare:hover {
  cursor: pointer;
  color: blue;
}

.input-warehouse-block {
  border: 1px solid grey;
  background-color: rgb(247, 245, 245);
  padding: 2px 5px;
  margin-right: 10px;
  height: 23px;
  width: 250px;
  cursor: no-drop;
}

.remove-warehouse-icon {
  margin-top: 5px;
  margin-left: 10px;
  fill: red;
  cursor: pointer;
}
